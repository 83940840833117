import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: [],
}

export const formDocument = createSlice({
  name: 'formDocument',
  initialState,
  reducers: {
    documentStore: (state,action) => {
      console.log(action.payload,'action.payload')
      state.value = action.payload
    },
    
  },
})

// Action creators are generated for each case reducer function
export const { documentStore } = formDocument.actions

export default formDocument.reducer