import React, { useState } from "react";
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
} from "@chakra-ui/react";
import { RxDashboard } from "react-icons/rx";
import { ToastContainer } from "react-toastify";
import logo from "../../Assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import booking from "../../Assets/images/booking.png";
import tax from "../../Assets/images/tax.png";

const LinkItems = [
  { name: "Dashboard", icon: RxDashboard, link: "/" },
  { name: "Accounting and CPA Exam", icon: RxDashboard, link: "/accounting" },
  { name: "LP Tax Service LLC", icon: RxDashboard, link: "/tax" },
  {
    name: "LP Accounting and Bookkeeping Services LLC",
    icon: RxDashboard,
    link: "/bookkeeping",
  },
  {
    name: "Blocked Dates",
    icon: RxDashboard,
    link: "/blockdates",
  },
  // {
  //   name: "Create Quiz",
  //   icon: RxDashboard,
  //   link: "/create-quiz",
  // },
  // {
  //   name: "Quiz Links",
  //   icon: RxDashboard,
  //   link: "/form-list",
  // },
];
export default function SimpleSidebar({ children, user, setUser }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [active, setActive] = useState("Dashboard");

  const handleActive = (name) => {
    setActive(name);
  };

  return (
    <>
      {user === true ? (
        <Box minH="100vh" borderRadius={"0px 20px 20px 0px"}>
          <SidebarContent
            onClose={() => onClose()}
            display={{ base: "none", md: "block" }}
            borderRadius={"0px 20px 20px 0px"}
            active={active}
            handleActive={handleActive}
            setUser={setUser}
          />
          <Drawer
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            returnFocusOnClose={false}
            onOverlayClick={onClose}
            size="full"
            borderRadius={"0px 20px 20px 0px"}
          >
            <DrawerContent>
              <SidebarContent
                onClose={onClose}
                onClick={(name) => handleActive(name)}
                active={active}
                handleActive={handleActive}
              />
            </DrawerContent>
          </Drawer>

          <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
          <Box ml={{ base: 0, md: 80 }} p="4">
            {children}
          </Box>
          <ToastContainer />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>{children}</Box>
      )}
    </>
  );
}

const SidebarContent = ({
  onClick,
  onClose,
  active,
  handleActive,
  setUser,
  ...rest
}) => {
  const navigate = useNavigate();
  const logout = () => {
    toast.success("Logout Successfully");
    localStorage.setItem("user", false);
    setUser(false);
  };
  return (
    <Box
      bg={"#0474BA"}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: "300px" }}
      pos="fixed"
      h="full"
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      {...rest}
    >
      <Flex
        h="20"
        alignItems="center"
        mx="8"
        justifyContent="space-between"
        // mt={38}
      >
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <Box sx={{ marginTop: "86px" }}>
        {LinkItems.map((link, index) => (
          <Link to={link.link} key={index}>
            <NavItem
              key={link.name}
              icon={link.icon}
              onClick={() => handleActive(link.name)}
              style={
                active === link.name
                  ? {
                      boxShadow: "0px 10px 30px 0px rgba(255, 153, 58, 0.20)",
                      borderRadius: "24.714px 0px 24.714px 24.714px",
                      background: "#F17720",
                      color: "white",
                    }
                  : { marginTop: "10px", marginBottom: "10px" }
              }
            >
              {link.name}
            </NavItem>
          </Link>
        ))}
      </Box>
      <Box sx={style.logutWraper}>
        <Box sx={style.logoutBox}>
          <Text sx={style.logoutText} onClick={logout}>
            LogOut
          </Text>
        </Box>
      </Box>
      <ToastContainer />
    </Box>
  );
};

const NavItem = ({ icon, children, ...rest }) => {
  return (
    <Box
      as="a"
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p="4"
        ml="4"
        borderRadius="24.714px 0px 24.714px 24.714px"
        role="group"
        cursor="pointer"
        color="white"
        fontSize={18}
        fontWeight={700}
        _hover={{
          color: "white",
          boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.16)",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            color="white"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton variant="outline" onClick={onOpen} aria-label="open menu" />

      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        Logo
      </Text>
    </Flex>
  );
};
const style = {
  logoutBox: {
    width: "263.518px",
    height: "49.428px",
    textAlign: "center",
    trasnition: "all ease-in-out 0.5s",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#F17720",
    boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.16)",
    borderRadius: "24.714px 0px 24.714px 24.714px",
    background: "white",
    ":hover": {
      boxShadow: "0px 10px 30px 0px rgba(255, 153, 58, 0.20)",
      borderRadius: "24.714px 0px 24.714px 24.714px",
      background: "#F17720",
      color: "white",
    },
  },
  logoutText: {
    color: "inherit",
    fontWeight: 700,

    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    cursor: "pointer",
  },
  logutWraper: {
    marginTop: "259px",
    width: "100%",
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
};
