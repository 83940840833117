import { LocalizationProvider } from "@mui/x-date-pickers";
import "./App.css";
import Dashboard from "./Components/Dashboard/Dashboard";
import SimpleSidebar from "./Components/SideBar/Sidebar";
import { ChakraBaseProvider } from "@chakra-ui/react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Login from "./Components/Login/Login";
import { useEffect, useState } from "react";
import Accounting from "../src/Components/Accounting/Accounting";
import Bookkeeping from "./Components/Bookkeeping/Bookkeeping";
import Tax from "./Components/Tax/Tax";
import CreateQuiz from "./Components/Create-quiz/CreateQuiz";
import UserList from "./Components/UserList/UserList";
import BlockDates from "./Components/BlockedDates/BlockedDates";
function App() {
  const [user, setUser] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Router>
        <SimpleSidebar user={user} setUser={setUser}>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <>{user ? <Dashboard /> : <Login setUser={setUser} />}</>
              }
            ></Route>
            <Route
              exact
              path="/accounting"
              element={
                <>{user ? <Accounting /> : <Login setUser={setUser} />}</>
              }
            ></Route>
            <Route
              exact
              path="/tax"
              element={<>{user ? <Tax /> : <Login setUser={setUser} />}</>}
            ></Route>
            <Route
              exact
              path="/bookkeeping"
              element={
                <>{user ? <Bookkeeping /> : <Login setUser={setUser} />}</>
              }
            ></Route>
            <Route
              exact
              path="/create-quiz"
              element={
                <>{user ? <CreateQuiz /> : <Login setUser={setUser} />}</>
              }
            ></Route>
            <Route
              exact
              path="/form-list"
              element={<>{user ? <UserList /> : <Login setUser={setUser} />}</>}
            ></Route>
            <Route
              exact
              path="/blockdates"
              element={
                <>{user ? <BlockDates /> : <Login setUser={setUser} />}</>
              }
            ></Route>
            <Route exact path="/login" element={<Login />}></Route>
          </Routes>
        </SimpleSidebar>
      </Router>
    </LocalizationProvider>
  );
}

export default App;
