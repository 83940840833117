import { db } from "../FirebaseConfig";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  setDoc,
  where,
  query,
  documentId,
} from "firebase/firestore";
import { BASE_URL } from "../config/config";
import axios from "axios";
export const getAllReservation = async () => {
  const reservations = [];
  const currentDate = new Date().toJSON().slice(0, 10);
  const querySnapshot = await getDocs(collection(db, "Appointments"));
  querySnapshot.forEach((doc) => {
    const reservationData = doc.data();

    const reservationDate = reservationData.date;

    if (new Date(reservationDate).toJSON().slice(0, 10) >= currentDate) {
      reservations.push(reservationData);
    }
  });

  return reservations;
};

export const getSpecificdata = async (id) => {
  const docRef = doc(db, "Appointments", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.data()) return { ...docSnap.data(), date: docSnap.id };
  else return { reservations: [], date: docSnap.id };
};

export const handleDelDocument = async (id, data) => {
  const Ref = doc(db, "Appointments", id);
  await setDoc(Ref, data);
  // await deleteDoc(doc(db, "Reservations", id));
};
export const addUpdatedTimeDataInFirebase = async (id, data) => {
  await setDoc(doc(db, "Appointments", id), data);
};

export const sendMailUpdate = async (data) => {
  console.log(data);

  const apiURL = `${BASE_URL}/api/change_reservation`;

  const response = await axios.post(apiURL, data);
  if (response.status === 200) {
    return response.data;
  } else {
    console.log(response.status);
  }
};

export const sendMailCancle = async (data) => {
  console.log(data);
  const apiURL = `${BASE_URL}/api/cancle_reservation`;

  const response = await axios.post(apiURL, data);
  if (response.status === 200) {
    return response.data;
  } else {
  }
};

export const addformReservation = async (id, data) => {
  await setDoc(doc(db, "Appointments", id), data);
};
export const getReservationsByDate = async (date) => {
  let temp = [];
  const q = query(
    collection(db, "Appointments"),
    where(documentId(), "==", date)
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    temp.push(doc.data());
  });
  if (temp.length > 0) return temp[0];
  else
    return {
      date: date,
      reservations: [],
    };
};
