import {
  Box,
  SimpleGrid,
  Text,
  border,
  Button,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Modal,
  ModalOverlay,
  Input,
} from "@chakra-ui/react";
import OverView from "./OverView";
import ReservationCard from "./ReservationCard";
import DetailedCard from "./DetailedCard";
import { useEffect, useState } from "react";
import {
  addformReservation,
  getReservationsByDate,
  handleDelDocument,
  sendMailUpdate,
} from "../../Services/Reservations";
import {
  getAllReservation,
  getSpecificdata,
  addUpdatedTimeDataInFirebase,
} from "../../Services/Reservations";
import Popup from "./Popup";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sendMailCancle } from "../../Services/login";
import React from "react";
import { isTimeWithinBounds } from "../../helpers/helpers";

function Dashboard() {
  const [reservations, setReservations] = useState([]);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [open, setOpen] = useState(false);
  const [currentReservations, setCurrentReservations] = useState();
  const [blockData, setBlockData] = useState({
    exactEndTime: new Date().toISOString().split("T")[0],
    exactStartTime: new Date().toISOString().split("T")[0],
    date: new Date().toLocaleDateString(),
  });
  const [specificDoc, setspecificDoc] = useState({
    data: null,
    index: null,
    appIndex: null,
    id: null,
    newSelectedDate: null,
  });

  const [counter, setCounter] = useState({
    accountingAndCpa: 0,
    lpTaxService: 0,
    lpAccounting: 0,
  });

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearch(query);
  };
  function getDayName(dateString) {
    var days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    var dayName = days[dateString.getDay()];
    return dayName;
  }

  const handleEdit = (id, appIndex, index, data) => {
    setspecificDoc({
      data: data,
      index: index,
      appIndex: appIndex,
      id: id,
      newSelectedDate: id,
    });
    setOpen(true);
  };
  const handleDeleteDocument = (id, appindex, index, sendMail) => {
    let temp = [...reservations];
    let year = new Date(id).toJSON().slice(0, 10).split("-")[0];
    let month = new Date(id).toJSON().slice(0, 10).split("-")[1];
    let day = new Date(id).toJSON().slice(0, 10).split("-")[2];
    let weekDayName = getDayName(new Date(id));
    const body = {
      firstName: temp[appindex].reservations[index].data.firstName,
      email: temp[appindex].reservations[index].data.email,
      lastName: temp[appindex].reservations[index].data.lastName,
      phone: temp[appindex].reservations[index].data.phone,
      requestedServices:
        temp[appindex].reservations[index].requestedServices.join(", "),
      date: `${month}/${day}/${year}  ${weekDayName}`,
      startTime: new Date(
        temp[appindex].reservations[index].exactStartTime.seconds * 1000
      ).toLocaleTimeString(),
      endTime: new Date(
        temp[appindex].reservations[index].exactEndTime.seconds * 1000
      ).toLocaleTimeString(),
      platform: temp[appindex].reservations[index].platform,
    };
    temp[appindex].reservations.splice(index, 1);
    setReservations(temp);
    handleDelDocument(id, temp[appindex])
      .then((data) => {
        if (sendMail) {
          sendMailCancle(body)
            .then(() => {})
            .catch((err) => console.log(err));
          toast.success("APPOINTMENT Canceled successfully");
        }
      })
      .catch((err) => console.log(err));
  };
  const handleDeleteDocumentNewButton = (id, appindex, index, sendMail) => {
    let temp = [...reservations];

    temp[appindex].reservations.splice(index, 1);
    setReservations(temp);
    handleDelDocument(id, temp[appindex])
      .then((data) => {
        if (sendMail) {
          toast.success("APPOINTMENT Deleted successfully");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSave = () => {
    if (
      !isTimeWithinBounds(
        new Date(specificDoc.data.exactStartTime.seconds * 1000)
          .toTimeString()
          .slice(0, 5),
        new Date(specificDoc.data.exactEndTime.seconds * 1000)
          .toTimeString()
          .slice(0, 5),
        new Date(specificDoc.newSelectedDate + "T00:00:00"),
        specificDoc.data.platform
      )
    ) {
      // toast.error("Time is out of reservation time.");
      toast.error("time is out of bound");
      return;
    }
    let temp = { ...reservations };
    if (
      specificDoc.newSelectedDate === specificDoc.id &&
      isTimeWithinBounds(
        new Date(specificDoc.data.exactStartTime.seconds * 1000)
          .toTimeString()
          .slice(0, 5),
        new Date(specificDoc.data.exactEndTime.seconds * 1000)
          .toTimeString()
          .slice(0, 5),
        new Date(specificDoc.newSelectedDate + "T00:00:00"),
        specificDoc.data.platform
      )
    ) {
      let year = new Date(specificDoc.id).toJSON().slice(0, 10).split("-")[0];
      let month = new Date(specificDoc.id).toJSON().slice(0, 10).split("-")[1];
      let day = new Date(specificDoc.id).toJSON().slice(0, 10).split("-")[2];
      let weekDayName = getDayName(new Date(specificDoc.id));
      sendMailUpdate({
        firstName: specificDoc.data.data.firstName,
        email: specificDoc.data.data.email,
        lastName: specificDoc.data.data.lastName,
        phone: specificDoc.data.data.phone,
        requestedServices: specificDoc.data.requestedServices.join(", "),
        message: "THE APPOINTMENT DATE AND TIME HAVE BEEN CHANGED. ",
        date: `${month}/${day}/${year}  ${weekDayName}`,
        startTime: new Date(
          specificDoc.data.exactStartTime.seconds * 1000
        ).toLocaleTimeString(),
        endTime: new Date(
          specificDoc.data.exactEndTime.seconds * 1000
        ).toLocaleTimeString(),
        platform: specificDoc.data.platform,
      });
      temp[specificDoc.appIndex].reservations[specificDoc.index] =
        specificDoc.data;
      handleDelDocument(specificDoc.id, temp[specificDoc.appIndex])
        .then((data) => {
          toast.success("Your Appointment has  been changed successfully");
          fetchData();
        })
        .catch((err) => console.log(err))
        .finally(() => setOpen(false));
    } else {
      //   console.log("if ma nh aya");
      let specificDocStartTime = new Date(
        specificDoc.data.exactStartTime.seconds * 1000
      )
        .toTimeString()
        .slice(0, 5);
      let specificDocEndTime = new Date(
        specificDoc.data.exactEndTime.seconds * 1000
      )
        .toTimeString()
        .slice(0, 5);
      let canReserve = true;
      getSpecificdata(specificDoc.newSelectedDate).then((data) => {
        let reservedTimes = data;
        if (
          reservedTimes?.reservations &&
          reservedTimes?.reservations?.length > 0
        ) {
          reservedTimes.reservations.forEach((element) => {
            const reservedStartHours = new Date(
              element.exactEndTime.seconds * 1000
            )
              .toTimeString()
              .slice(0, 5)
              .split(":")[0];
            const reservedStartMinuts = new Date(
              element.exactEndTime.seconds * 1000
            )
              .toTimeString()
              .slice(0, 5)
              .split(":")[1];
            const reservedEndtHours = new Date(
              element.exactEndTime.seconds * 1000
            )
              .toTimeString()
              .slice(0, 5)
              .split(":")[0];
            const reservedEndMinuts = new Date(
              element.exactEndTime.seconds * 1000
            )
              .toTimeString()
              .slice(0, 5)
              .split(":")[1];
            const appliedStartHours = specificDocStartTime.split(":")[0];
            const appliedStartMinuts = specificDocStartTime.split(":")[1];
            const appliedEndtHours = specificDocEndTime.split(":")[0];
            const appliedEndMinuts = specificDocEndTime.split(":")[1];

            if (
              ((appliedStartHours > reservedStartHours ||
                (appliedStartHours === reservedStartHours &&
                  appliedStartMinuts >= reservedStartMinuts)) &&
                (appliedEndtHours < reservedEndtHours ||
                  (appliedEndtHours === reservedEndtHours &&
                    appliedEndMinuts <= reservedEndMinuts))) ||
              (appliedStartHours === reservedEndtHours &&
                appliedStartMinuts === reservedEndMinuts) ||
              (appliedEndtHours === reservedStartHours &&
                appliedEndMinuts === reservedStartMinuts) ||
              (appliedEndtHours < appliedStartHours &&
                (appliedStartHours > reservedStartHours ||
                  (appliedStartHours === reservedStartHours &&
                    appliedStartMinuts >= reservedStartMinuts) ||
                  appliedEndtHours < reservedEndtHours ||
                  (appliedEndtHours === reservedEndtHours &&
                    appliedEndMinuts <= reservedEndMinuts))) ||
              (appliedEndtHours === reservedStartHours &&
                appliedEndMinuts === reservedStartMinuts)
            ) {
              canReserve = false;
              console.log("Applied time overlaps with reserved time");
            } else {
              // The applied time overlaps with the reserved time

              return;
            }
          });
        }
        if (
          canReserve &&
          isTimeWithinBounds(
            new Date(specificDoc.data.exactStartTime.seconds * 1000)
              .toTimeString()
              .slice(0, 5),
            new Date(specificDoc.data.exactEndTime.seconds * 1000)
              .toTimeString()
              .slice(0, 5),
            new Date(specificDoc.newSelectedDate + "T00:00:00"),
            specificDoc.data.platform
          )
        ) {
          toast.success("Your Appointment has  been changed successfully");
          handleDeleteDocument(
            specificDoc.id,
            specificDoc.appIndex,
            specificDoc.index
          );
          let documentId = specificDoc.newSelectedDate;
          let year = new Date(documentId).toJSON().slice(0, 10).split("-")[0];
          let month = new Date(documentId).toJSON().slice(0, 10).split("-")[1];
          let day = new Date(documentId).toJSON().slice(0, 10).split("-")[2];
          let weekDayName = getDayName(new Date(documentId));
          sendMailUpdate({
            firstName: specificDoc.data.data.firstName,
            email: specificDoc.data.data.email,
            lastName: specificDoc.data.data.lastName,
            phone: specificDoc.data.data.phone,
            requestedServices: specificDoc.data.requestedServices.join(", "),
            message: "THE APPOINTMENT DATE AND TIME HAVE BEEN CHANGED. ",
            date: `${month}/${day}/${year}  ${weekDayName}`,
            startTime: new Date(
              specificDoc.data.exactStartTime.seconds * 1000
            ).toLocaleTimeString(),
            endTime: new Date(
              specificDoc.data.exactEndTime.seconds * 1000
            ).toLocaleTimeString(),
            platform: specificDoc.data.paltform,
          });
          let temp = { ...reservedTimes };
          temp.reservations.push(specificDoc.data);
          addUpdatedTimeDataInFirebase(documentId, temp).then((data) =>
            console.log(data)
          );
          fetchData();

          setOpen(false);
        } else {
          toast.error(
            "This time is already reserved Please select another time"
          );
        }
      });
    }
  };

  const onChangeDate = (e) => {
    setBlockData({
      id: e.target.value,
    });
    getReservationsByDate(e.target.value).then((data) =>
      setCurrentReservations(data)
    );
  };
  const handleBlockData = () => {
    let temp = { ...currentReservations };
    let exactStartTime = new Date(
      new Date(blockData.id).setHours(
        blockData.exactStartTime?.split(":")[0],
        blockData.exactStartTime?.split(":")[1]
      )
    );
    let exactEndTime = new Date(
      new Date(blockData.id).setHours(
        blockData.exactEndTime?.split(":")[0],
        blockData.exactEndTime?.split(":")[1]
      )
    );
    temp.reservations.push({
      exactStartTime: exactStartTime,
      exactEndTime: exactEndTime,
      data: {},
      requestedServices: [],
      isBlocked: true,
    });
    addformReservation(temp.date, temp).then(() => {
      toast.success("Reservations Blocked Sucessfully");
      onClose();
    });
  };
  useEffect(() => {
    console.log(blockData);
  }, [blockData]);
  const reservationCount = () => {
    let obj = {
      accountingAndCpa: 0,
      lpTaxService: 0,
      lpAccounting: 0,
    };
    reservations?.forEach((item) => {
      item.reservations.forEach((reservation) => {
        if (reservation.platform === "CpaAccounting") {
          obj.accountingAndCpa += 1;
        } else if (reservation.platform === "LpBooking") {
          obj.lpAccounting += 1;
        } else if (reservation.platform === "LpTax") {
          obj.lpTaxService += 1;
        }
      });
    });
    setCounter({
      accountingAndCpa: obj.accountingAndCpa,
      lpTaxService: obj.lpTaxService,
      lpAccounting: obj.lpAccounting,
    });
  };
  const chnageDate = (event) => {
    let temp = event.target.value;
    setspecificDoc((prev) => ({
      ...prev,
      newSelectedDate: temp,
    }));
  };
  const fetchData = () => {
    getAllReservation().then((data) => {
      setReservations(data);
    });
  };
  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 10000);

    fetchData();

    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    reservationCount();
  }, [reservations]);

  return (
    <>
      <OverView handleSearch={handleSearch} search={search} />
      <ReservationCard
        lpAccounting={counter.lpAccounting}
        accountingAndCpa={counter.accountingAndCpa}
        lpTaxService={counter.lpTaxService}
      />
      <Box sx={style.main}>
        <Text sx={style.reservationHeading}>
          {reservations.length === 1 ? "APPOINTMENT:" : "APPOINTMENTS :"}
        </Text>
        <Button
          bg={"#369FFF"}
          color={"white"}
          px={"20px"}
          borderRadius={"20px"}
          gap={"5px"}
          py={"5px"}
          boxShadow={"0px 4px 4px 0px rgba(0, 0, 255, 0.4)"}
          onClick={onOpen}
        >
          Block Date
        </Button>
      </Box>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) " />
        <ModalContent
          w={"50%"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          bg={"white"}
          paddingY={10}
          paddingX={5}
          sx={{
            boxShadow: "0px 3px 18px 0px rgba(0,0,0,0.5)",
            borderRadius: "1rem",
            border: "1px solid orange",
            height: "300px",
          }}
        >
          <ModalHeader
            sx={{
              position: "absolute",
              textAlign: "left",
              left: "30px",
              top: "20px",
              fontSize: "1.2rem",
              fontWeight: 700,
            }}
          >
            Block Date
          </ModalHeader>
          <ModalCloseButton
            sx={{ position: "absolute", right: "20px", top: "20px" }}
          />
          <ModalBody sx={{ display: "flex", gap: "30px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontWeight: 600,
                  fontSize: "17px",
                  paddingBottom: "8px",
                }}
              >
                Date
              </label>
              <Input
                placeholder="Select Date and Time"
                size="md"
                type="date"
                min={new Date().toISOString().split("T")[0]}
                onChange={onChangeDate}
                sx={{
                  border: "1px solid grey",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontWeight: 600,
                  fontSize: "17px",
                  paddingBottom: "8px",
                }}
              >
                Start Time
              </label>
              <Input
                placeholder="Select Date and Time"
                size="md"
                type="time"
                min={new Date().toISOString().split("T")[0]}
                onChange={(e) =>
                  setBlockData({
                    ...blockData,
                    exactStartTime: e.target.value,
                  })
                }
                sx={{
                  border: "1px solid grey",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontWeight: 600,
                  fontSize: "17px",
                  paddingBottom: "8px",
                }}
              >
                End Time
              </label>
              <Input
                placeholder="Select Date and Time"
                size="md"
                type="time"
                min={new Date().toISOString().split("T")[0]}
                onChange={(e) =>
                  setBlockData({
                    ...blockData,
                    exactEndTime: e.target.value,
                  })
                }
                sx={{
                  border: "1px solid grey",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter
            sx={{
              position: "absolute",
              right: "30px",
              bottom: "30px",
            }}
          >
            <Button
              variant="solid"
              sx={{
                background: "red",
                color: "white",
                paddingX: "25px",
                paddingY: "10px",
                borderRadius: "12px",
              }}
              boxShadow={"0px 4px 4px 0px rgba(255, 0, 0, 0.4)"}
              onClick={handleBlockData}
            >
              Block
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {reservations &&
        reservations.map((appointments, appindex) => {
          let year = new Date(appointments.date)
            .toJSON()
            .slice(0, 10)
            .split("-")[0];
          let month = new Date(appointments.date)
            .toJSON()
            .slice(0, 10)
            .split("-")[1];
          let day = new Date(appointments.date)
            .toJSON()
            .slice(0, 10)
            .split("-")[2];
          return (
            <React.Fragment key={appindex}>
              <Box sx={{ fontSize: "24px", fontWeight: 700, margin: "10px" }}>
                {appointments.reservations.filter((data) =>
                  data?.data?.email?.includes(search)
                ).length > 0 && `Date : ${month}/${day}/${year}`}
              </Box>
              <SimpleGrid
                columns={{ base: 1, md: 1, lg: 1, xl: 2 }}
                spacing={6}
              >
                {appointments.reservations.filter(
                  (data) =>
                    data?.data?.email?.includes(search) ||
                    data?.data?.firstName?.includes(search) ||
                    data?.data?.LastName?.includes(search)
                ).length > 0 ? (
                  appointments.reservations
                    .filter(
                      (data) =>
                        data?.data?.email?.includes(search) ||
                        data?.data?.firstName?.includes(search) ||
                        data?.data?.LastName?.includes(search)
                    )
                    ?.map((data, index) => {
                      console.log(
                        new Date(appointments.date).toJSON().slice(0, 10),
                        "date"
                      );
                      return (
                        <DetailedCard
                          key={index}
                          firstName={data.data?.firstName}
                          LastName={data.data?.lastName}
                          email={data.data?.email}
                          phone={data.data?.phone}
                          services={data?.requestedServices}
                          startTime={data.exactStartTime}
                          endTime={data.exactEndTime}
                          message={data.data?.message}
                          meetingType={data?.meetingType}
                          date={new Date(appointments.date)
                            .toJSON()
                            .slice(0, 10)}
                          onClickDel={() =>
                            handleDeleteDocument(
                              appointments.date,
                              appindex,
                              index,
                              true
                            )
                          }
                          onClickDelNew={() =>
                            handleDeleteDocumentNewButton(
                              appointments.date,
                              appindex,
                              index,
                              true
                            )
                          }
                          onClick={() =>
                            handleEdit(appointments.date, appindex, index, data)
                          }
                        />
                      );
                    })
                ) : (
                  <></>
                )}
              </SimpleGrid>
            </React.Fragment>
          );
        })}

      {specificDoc && (
        <Popup
          firstName={specificDoc?.data?.data?.firstName}
          LastName={specificDoc?.data?.data?.lastName}
          email={specificDoc?.data?.data?.email}
          phone={specificDoc?.data?.data?.phone}
          services={specificDoc?.data?.requestedServices}
          message={specificDoc?.data?.data?.message}
          AppointmentstartTime={specificDoc?.data?.exactStartTime}
          AppointmentendTime={specificDoc?.data?.exactEndTime}
          handleSave={() => handleSave()}
          id={specificDoc?.id}
          onClose={() => setOpen(false)}
          isOpen={open}
          setspecificDoc={setspecificDoc}
          specificDoc={specificDoc}
          chnageDate={(e) => chnageDate(e)}
        />
      )}
      <ToastContainer />
    </>
  );
}

export default Dashboard;
const style = {
  main: {
    width: "100%",
    height: "100%",
    position: "relative",
    marginTop: "30px",
    paddingX: "1rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingRight: 20,
  },
  reservationHeading: {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    textTransform: "uppercase",
    marginBottom: "0.5rem",
    opacity: 0.7,
  },
};
