import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { SimpleGrid } from "@chakra-ui/react";
import shape from "../../Assets/images/s.png";
import shape1 from "../../Assets/images/s1.png";
import o from "../../Assets/images/o.png";
import o1 from "../../Assets/images/o1.png";
import accountingCPA from "../../Assets/images/accountingCPA.svg";
import tax from "../../Assets/images/tax.svg";
import boking from "../../Assets/images/booking.svg";

function ReservationCard({ lpAccounting, accountingAndCpa, lpTaxService }) {
  return (
    <Box sx={style.main}>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={6}>
        <Box sx={style.cardBox}>
          <img
            alt=";oops"
            src={shape}
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              width: "161px",
              height: "95px",
            }}
          />
          <img
            alt=";oops"
            src={shape1}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
          <img
            alt=";oops"
            src={accountingCPA}
            style={{
              width: "70px",
              height: "70px",
            }}
          />
          <Text sx={style.boldFont}>Total APPOINTMENT</Text>
          <Text sx={style.date}>Accounting and CPA Exam</Text>
          <Flex justify={"end"} w={"100%"} mt={"23px"} alignItems={"center"}>
            <Text sx={style.totalNumber}>{accountingAndCpa}</Text>
          </Flex>
        </Box>
        <Box sx={style.cardBox1}>
          <img
            alt=";oops"
            src={o}
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              width: "161px",
              height: "95px",
            }}
          />
          <img
            alt=";oops"
            src={o1}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
          <img
            alt=";oops"
            src={tax}
            style={{
              width: "70px",
              height: "70px",
            }}
          />
          <Text sx={style.boldFont}>Total APPOINTMENT</Text>
          <Text sx={style.date}>LP Tax Service LLC</Text>
          <Flex justify={"end"} w={"100%"} mt={"23px"} alignItems={"center"}>
            <Text sx={style.totalNumber}>{lpTaxService}</Text>
          </Flex>
        </Box>
        <Box sx={style.cardBox}>
          <img
            alt=";oops"
            src={shape}
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              width: "161px",
              height: "95px",
            }}
          />
          <img
            alt=";oops"
            src={shape1}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
          <img
            alt=";oops"
            src={boking}
            style={{
              width: "70px",
              height: "70px",
            }}
          />
          <Text sx={style.boldFont}>Total APPOINTMENT</Text>
          <Text sx={style.date}>
            LP Accounting and Bookkeeping Services LLC
          </Text>
          <Flex justify={"end"} w={"100%"} mt={"23px"} alignItems={"center"}>
            <Text sx={style.totalNumber}>{lpAccounting}</Text>
          </Flex>
        </Box>
      </SimpleGrid>
    </Box>
  );
}

export default ReservationCard;
const style = {
  main: {
    width: "100%",
    height: "100%",
    position: "relative",
    marginTop: "50px",
    padding: "20px",
  },
  cardBox: {
    width: "100%",
    height: "100%",
    position: "relative",
    padding: "20px",
    borderRadius: "20px",
    background: "#369FFF",
    boxShadow: "0px 10px 30px 0px rgba(54, 159, 255, 0.40)",
  },
  cardBox1: {
    width: "100%",
    height: "100%",
    position: "relative",
    padding: "20px",
    borderRadius: "20px",
    background: "#FF993A",
    boxShadow: "0px 10px 30px 0px rgba(255, 153, 58, 0.40)",
  },
  boldFont: {
    color: "white",
    marginY: "0.3rem",
    fontWeight: 700,
    fontSize: "23px",
    lineHeight: "normal",
    textTransform: "uppercase",
    fontFamily: "Poppins",
    position: "relative",
  },
  date: {
    color: "#F0F0F0",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    marginBottom: "23px",
  },
  totalNumber: {
    color: "#FFF",
    fontFamily: "Poppins",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    position: "relative",
  },
};
