import {
  ChakraBaseProvider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, Button, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import three from "../../Assets/images/3.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { updateSpecificDoc } from "../../Services/Reservations";
import { sendMailUpdate } from "../../Services/login";
import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";

function Popup({
  firstName,
  LastName,
  email,
  phone,
  services,
  message,
  id,
  onClose,
  isOpen,
  AppointmentstartTime,
  AppointmentendTime,
  setspecificDoc,
  specificDoc,
  handleSave,
  chnageDate,
}) {
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [load, setIsLoad] = useState(false);

  function timeToMinutes(timeString) {
    // Split the time string into hours, minutes, and AM/PM parts
    const [time, period] = timeString.split(" ");
    const [hours, minutes] = time.split(":").map(Number);

    // Calculate the total minutes
    let totalMinutes = hours * 60 + minutes;

    // Adjust for PM if necessary
    if (period === "PM" && hours !== 12) {
      totalMinutes += 12 * 60; // Add 12 hours for PM
    }

    return totalMinutes;
  }

  const handleStartTimeChange = (event) => {
    let time = event.target.value;

    let exactStartTime = new Date(id).setHours(
      time?.split(":")[0],
      time?.split(":")[1]
    );
    let temp = { ...specificDoc };
    temp.data.exactStartTime.seconds = exactStartTime / 1000;
    setspecificDoc(temp);
  };
  const handleEndTimeChange = (event) => {
    let time = event.target.value;

    let exactEndTime = new Date(id).setHours(
      time?.split(":")[0],
      time?.split(":")[1]
    );
    let temp = { ...specificDoc };
    temp.data.exactEndTime.seconds = exactEndTime / 1000;
    setspecificDoc(temp);
  };
  useEffect(() => console.log(startTime, endTime), [startTime, endTime]);

  const updateDocHandler = () => {
    setIsLoad(true);
    const startFinalTime = timeToMinutes(startTime);
    const endFinalTime = timeToMinutes(endTime);
    if (endFinalTime - startFinalTime === 60) {
      const updatedDate = `${startDate},${startTime},${endTime}`;
    } else {
      setIsLoad(false);
      onClose();
      alert("diffrence between start and end time is more than an hour");
    }
  };

  useEffect(() => {
    let timeToStart = new Date(AppointmentstartTime?.seconds * 1000);
    let timeToEnd = new Date(AppointmentendTime?.seconds * 1000);
    setStartTime(timeToStart.toTimeString().slice(0, 5));
    setEndTime(timeToEnd.toTimeString().slice(0, 5));
  }, [AppointmentstartTime]);
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ModalContent
          w={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <ModalBody
            w={{ xl: "50%", base: "100%" }}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box sx={style.detaildCard} w={{ md: "auto", base: "80%" }}>
              <ModalCloseButton />
              <Box
                w={"100%"}
                display={"flex"}
                justify={"space-between"}
                alignItems={{ sm: "center", base: "start" }}
                className="popupBox"
              >
                <Box w={"100%"} mt={{ md: "5px", base: "12px" }}>
                  <Box mb={"12px"}>
                    <Text sx={style.labelName}>FIRST Name </Text>
                    <Text sx={style.scott}>{`${firstName}`} </Text>
                  </Box>
                  <Box mb={"12px"}>
                    <Text sx={style.labelName}>LAST Name </Text>
                    <Text sx={style.scott}>{`${LastName}`} </Text>
                  </Box>
                  <Box mb={"12px"}>
                    <Text sx={style.labelName}>Phone </Text>
                    <Text sx={style.phone}>{phone} </Text>
                  </Box>
                  <Box mb={"12px"}>
                    <Text sx={style.labelName}>Email </Text>
                    <Text sx={style.email}>{email}</Text>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"end"}
                  alignItems={"end"}
                  mt={"10px"}
                >
                  <Box sx={style.innerBox} w={{ md: "444px", base: "100%" }}>
                    {/* <img
                      alt="oop"
                      src={three}
                      style={{
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                        top: "10px",
                      }}
                    /> */}
                    <Text sx={style.goldenHeading}> Appointment Time: </Text>
                    <Box
                      className="popupBox"
                      sx={{
                        display: "flex",

                        gap: "0.5rem",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box>
                        <input
                          type="date"
                          value={specificDoc.newSelectedDate}
                          onChange={(e) => chnageDate(e)}
                          min={new Date().toISOString().split("T")[0]}
                        />
                      </Box>

                      <Box>
                        <input
                          type="time"
                          onChange={(e) => handleStartTimeChange(e)}
                          value={new Date(
                            specificDoc?.data?.exactStartTime?.seconds * 1000
                          )
                            .toTimeString()
                            .slice(0, 5)}
                        />
                      </Box>
                      <Box>
                        <input
                          type="time"
                          onChange={(e) => handleEndTimeChange(e)}
                          value={new Date(
                            specificDoc?.data?.exactEndTime?.seconds * 1000
                          )
                            .toTimeString()
                            .slice(0, 5)}
                        />
                      </Box>
                    </Box>
                    <Text sx={style.goldenHeading} mt={"30px"}>
                      Requested Services:{" "}
                    </Text>
                    {services &&
                      services.map((chunk, index) => (
                        <Text sx={style.whiteHeading} key={index}>
                          {chunk}
                        </Text>
                      ))}
                  </Box>
                </Box>
              </Box>
              <Text sx={style.labelName} mt={{ md: "0px", base: "12px" }}>
                Message{" "}
              </Text>
              <Text sx={style.para}>{message}</Text>
              <ModalFooter>
                <Button
                  variant="solid"
                  sx={{
                    background:
                      "linear-gradient(180deg, #197BBD 0%, #3391D0 100%)",
                    color: "white",
                    paddingX: "25px",
                    paddingY: "10px",
                    borderRadius: "12px",
                  }}
                  onClick={handleSave}
                >
                  {load ? (
                    <CircularProgress
                      isIndeterminate
                      color="white"
                      size={"10px"}
                    />
                  ) : (
                    "Save "
                  )}
                </Button>
              </ModalFooter>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default Popup;
const style = {
  detaildCard: {
    position: "relative",
    padding: "1rem",
    borderRadius: "28px",
    border: "1px solid #F17720",
    background: "linear-gradient(180deg, #FFF 0%, #FFF 100%)",

    boxShadow:
      "0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.06)",
  },
  iconsBox: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    alignItems: "end",
  },
  labelName: {
    color: "#AEAEAE",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  scott: {
    color: "#197BBD",
    fontFamily: "Poppins",
    fontSize: "31.55px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  phone: {
    color: "#439A86",
    fontFamily: "Poppins",
    fontSize: "25px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  email: {
    color: "#BB4430",
    fontFamily: "Poppins",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  innerBox: {
    borderRadius: "26px",
    background: "linear-gradient(180deg, #197BBD 0%, #3391D0 100%)",
    padding: "13px",
    position: "relative",
  },
  goldenHeading: {
    color: "#FFD84D",
    fontFamily: "Poppins",
    fontSize: { base: "12px", lg: "16px" },
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  whiteHeading: {
    color: "white",
    fontFamily: "Poppins",
    fontSize: { base: "12px", lg: "16px" },
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginY: "5px",
  },
  para: {
    fontSize: "13px",
  },
};
