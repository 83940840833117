import { db } from "../FirebaseConfig";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  getDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { BASE_URL } from "../config/config";
import axios from "axios";

export const loginCheck = async () => {
  let temp = {};
  const querySnapshot = await getDocs(collection(db, "admin"));
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    temp = doc.data();
  });
  return temp;
};
export const sendMailUpdate = async (data) => {
  const apiURL = `${BASE_URL}/api/change_reservation`;

  const response = await axios.post(apiURL, data);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("Request failed with status: " + response.status);
  }
};

export const sendMailCancle = async (data) => {
  const apiURL = `${BASE_URL}/api/cancle_reservation`;

  const response = await axios.post(apiURL, data);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("Request failed with status: " + response.status);
  }
};
