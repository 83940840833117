import {
  Button,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { Box } from "@mui/system";
import {
  ChakraBaseProvider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { MdContentCopy } from "react-icons/md";
import React, { useEffect, useState } from "react";
import { ImCheckboxChecked } from "react-icons/im";
import { MdTextFields } from "react-icons/md";
import { PiRadioButtonFill } from "react-icons/pi";
import { FaCheckSquare } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { documentStore } from "../../Redux/Reducer";
import { TextField, Tooltip } from "@mui/material";
import { QuizLink, quizFormSubmit } from "../../Services/SendQuizDocument";
import { CopyToClipboard } from "react-copy-to-clipboard";
const mutipleChoiceInitialData = [
  {
    type: "Multiple",
    question: "",
    choices: [{ option: "" }],
    answer: "",
    reason: "",
  },
];

function CreateQuiz() {
  const dispatch = useDispatch();
  const [linkId, setLinkId] = useState("");
  const [quizName, setQuizName] = useState(" ");
  const [disableCraeteButton, setDisableCraeteButton] = useState(false);
  const [ShowQuizName, setShowQuizName] = useState(true);
  const [isQuizFinish, setIsQuizFinish] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isQuizLinkModal,
    onOpen: onOpenQuizLinkModal,
    onClose: onCloseQuizLinkModal,
  } = useDisclosure();
  const [selectedOption, setSelectedOption] = useState("");

  const [value, setValue] = React.useState("1");
  const [singleValue, setSingleValue] = useState({
    type: "",
    question: "",
    answer: "True",
    reason:""
  });
  const [CurrentMultipleChoice, setCurrentMultipleChoice] = useState(
    mutipleChoiceInitialData
  );
  const [isCopied, setIsCopied] = useState(false);
  const [isCurrentQuizLink, setIsCurrentQuizLink] = useState("");

  const [questionsObject, setQuestionsObject] = useState([]);
  const handleFinish = () => {
    if (selectedOption === "Multiple") {
      const newArray = [...questionsObject, CurrentMultipleChoice[0]];
      const temp = {
        quizDocument: newArray,
        quizName: quizName,
      };
      quizFormSubmit(temp).then((id) => {
        const link = `https://clever-florentine-372d6d.netlify.app/quiz/${id}`;

        QuizLink(link, quizName)
          .then((data) => {
            console.log("send");
            setIsCurrentQuizLink(link);
          })
          .catch((err) => console.error(err));
      });
    } else {
      const newArray = [...questionsObject, singleValue];
      const temp = {
        quizDocument: newArray,
        quizName: quizName,
      };
      quizFormSubmit(temp).then((id) => {
        const link = `https://clever-florentine-372d6d.netlify.app/quiz/${id}`;
        QuizLink(link, quizName)
          .then((data) => {
            console.log("send");
            setIsCurrentQuizLink(link);
          })
          .catch((err) => console.error(err));
      });
      setQuestionsObject(newArray);
    }

    setIsQuizFinish(true);
  };
  const handleAddMore = (docId) => {
    const copy = [...CurrentMultipleChoice];
    copy[docId].choices.push({ option: "" });
    setCurrentMultipleChoice(copy);
  };

  const handleQuestionChange = (e, indexss) => {
    const questionss = e.target.value;
    const copy = [...CurrentMultipleChoice];
    copy[indexss].question = questionss;
    copy[indexss].type = selectedOption;
    setCurrentMultipleChoice(copy);
  };

  const handleChoiceChange = (e, questionId, objId) => {
    const choice = e.target.value;
    const copy = [...CurrentMultipleChoice];
    copy[objId].choices[questionId].option = choice;
    setCurrentMultipleChoice(copy);
  };
  const handleBooleanQuestion = (e) => {
    const question = e.target.value;
    setSingleValue((prev) => ({
      ...prev,
      question: question,
      type: selectedOption,
    }));
  };
  const handleReasonBoolean = (e) => {
    const reason = e.target.value;
    setSingleValue((prev) => ({
      ...prev,
      reason: reason,
   
    }));
  };
  const handleRightAnswer = (e, questionId) => {
    const multipleChoiceAnswer = e.target.value;
    const temp = [...CurrentMultipleChoice];
    temp[questionId].answer = multipleChoiceAnswer;
    setCurrentMultipleChoice(temp);
  };
  const handleReason = (e, questionId) => {
    const reason = e.target.value;
    const temp = [...CurrentMultipleChoice];
    temp[questionId].reason = reason;
    setCurrentMultipleChoice(temp);
  };
  const handleTextAnswer = (e) => {
    const answer = e.target.value;
    setSingleValue((prev) => ({
      ...prev,
      answer: answer,
    }));
  };

  const handleNext = () => {
    if (selectedOption === "Multiple") {
      const newArray = [...questionsObject, CurrentMultipleChoice[0]];
      setQuestionsObject(newArray);
      setCurrentMultipleChoice(
        JSON.parse(JSON.stringify(mutipleChoiceInitialData))
      );
      setSelectedOption("");
    } else {
      const newArray = [...questionsObject, singleValue];
      setQuestionsObject(newArray);
    }
    onOpen();
  };
  const handleQuizName = (e) => {
    setQuizName(e.target.value);
  };
  const hideQuizNameAndDisableButtonShowQuestion = () => {
    setDisableCraeteButton(true);
    setShowQuizName(false);
  };
  const handleSelectOption = (name) => {
    setSelectedOption(name);
    onClose();
    setDisableCraeteButton(true);
  };
  const handleCopyClick = (link) => {
    const textarea = document.createElement("textarea");

    textarea.value = link;

    document.body.appendChild(textarea);

    textarea.select();

    document.execCommand("copy");

    document.body.removeChild(textarea);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };
  const onCopy = () => {
    setIsCopied(true);
  };

  useEffect(() => {
    setSingleValue((prev) => ({
      ...prev,
      answer: value,
    }));
  }, [value]);

  return (
    <Box sx={style.main}>
      <Button disabled={disableCraeteButton} sx={style.btn} onClick={onOpen}>
        Create +
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ModalContent
          w={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <ModalBody
            w={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {ShowQuizName && (
              <Box sx={style.detaildCard}>
                <ModalCloseButton />
                <Box w={"100%"} p={3} className="popupBox">
                  <Text
                    sx={{ fontSize: "20px", fontWeight: 600, marginY: "15px" }}
                  >
                    Enter Quiz Name
                  </Text>
                  <input
                    style={{
                      padding: "10px",
                      marginY: "20px",
                      border: "1px solid grey",
                      borderRadius: "12px",
                      width: "100%",
                    }}
                    variant="outlined"
                    placeholder="Enter Quiz Name"
                    onChange={(e) => handleQuizName(e)}
                  />
                </Box>
                <Button
                  variant="solid"
                  sx={{
                    background:
                      "linear-gradient(180deg, #197BBD 0%, #3391D0 100%)",
                    color: "white",
                    paddingX: "25px",
                    paddingY: "10px",
                    borderRadius: "12px",
                    marginLeft: "1.5rem",
                  }}
                  onClick={() => hideQuizNameAndDisableButtonShowQuestion()}
                >
                  Start
                </Button>
              </Box>
            )}
            {!ShowQuizName && (
              <Box sx={style.detaildCard}>
                <ModalCloseButton />
                <Box
                  w={"100%"}
                  display={"flex"}
                  p={3}
                  justify={"space-between"}
                  alignItems={"start"}
                  className="popupBox"
                >
                  <Box>
                    <Text
                      sx={{
                        fontSize: "25px",
                        fontWeight: 600,
                        opacity: 0.9,
                        paddingY: "0.5rem",
                      }}
                    >
                      Select Question Type ?
                    </Text>
                    <Box w={"100%"} mt={"12px"}>
                      <Box
                        sx={style.listItems}
                        onClick={() => handleSelectOption("Multiple")}
                      >
                        <ImCheckboxChecked size={20} />
                        <Text
                          sx={{
                            fontSize: "19px",
                            fontWeight: 600,
                            opacity: 0.8,
                          }}
                        >
                          Multiple Choice
                        </Text>
                      </Box>
                      <Box
                        sx={style.listItems}
                        onClick={() => handleSelectOption("Boolean")}
                      >
                        <PiRadioButtonFill size={20} />
                        <Text
                          sx={{
                            fontSize: "19px",
                            fontWeight: 600,
                            opacity: 0.8,
                          }}
                        >
                          True / False
                        </Text>
                      </Box>
                      <Box
                        sx={style.listItems}
                        onClick={() => handleSelectOption("SText")}
                      >
                        <MdTextFields size={22} />
                        <Text
                          sx={{
                            fontSize: "19px",
                            fontWeight: 600,
                            opacity: 0.8,
                          }}
                        >
                          Short Text
                        </Text>
                      </Box>
                      <Box
                        sx={style.listItems}
                        onClick={() => handleSelectOption("LText")}
                      >
                        <MdTextFields size={22} />
                        <Text
                          sx={{
                            fontSize: "19px",
                            fontWeight: 600,
                            opacity: 0.8,
                          }}
                        >
                          Long Text
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <ModalFooter>
                  <Button
                    variant="solid"
                    sx={{
                      background:
                        "linear-gradient(180deg, #197BBD 0%, #3391D0 100%)",
                      color: "white",
                      paddingX: "25px",
                      paddingY: "10px",
                      borderRadius: "12px",
                    }}
                  >
                    Add
                  </Button>
                </ModalFooter>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      {quizName !== "" && (
        <Text
          sx={{
            textAlign: "center",
            fontWeight: 600,
            marginTop: "3rem",
            marginBottom: "1rem",
            fontSize: "35px",
          }}
        >
          {quizName}
        </Text>
      )}
      <div id="form">
        {questionsObject.map((item, index) => (
          <React.Fragment key={index}>
            {item.question.length > 0 && (
              <Text fontSize={"30px"} fontWeight={600} my={5}>
                Q # {index + 1} : {item.question}
              </Text>
            )}
            {item.question.length > 0 && (
              <Box sx={{ display: "inline-grid" }}>
                {item.type === "Multiple" &&
                  item?.choices.map((data, index) => (
                    <label
                      style={{ paddingTop: "10px", fontSize: "20px" }}
                      key={index}
                    >
                      <input type="checkbox" />
                      &nbsp; {data.option}
                    </label>
                  ))}
                {item.type === "Boolean" && (
                  <RadioGroup sx={{ paddingX: "1rem" }}>
                    <Stack direction="column">
                      <Radio
                        size="lg"
                        name={`${index}`}
                        colorScheme="orange"
                        value="True"
                      >
                        True
                      </Radio>
                      <Radio
                        size="lg"
                        name={`${index}`}
                        colorScheme="orange"
                        value="False"
                      >
                        False
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}
                {(item.type === "SText" || item.type === "LText") && (
                  <Input placeholder="Enter your Answer" />
                )}
              </Box>
            )}
            {item.question.length > 0 && (
              <Text
                sx={{ color: "green", fontWeight: 600, paddingTop: "10px" }}
              >
                Correct Answer is : {item.answer}
              </Text>
            )}
            {item.question.length > 0 && (
              <Text
                sx={{ color: "green", fontWeight: 600, paddingTop: "10px" }}
              >
                Reason : {item.reason}
              </Text>
            )}
          </React.Fragment>
        ))}
      </div>
      {/*------------------------------------------------- MODAL CODE END------------------------------------------------- */}
      {/* --------------------------------------------------------------------------------------------------------------------------------------------------- */}
      {/*------------------------------------------------- QUESTION VISIBILTY CONDITIONALLY PART START-------------------------------------------------*/}
      {!isQuizFinish && (
        <Box sx={style.questionBox}>
          {selectedOption === "Multiple" && (
            <>
              {CurrentMultipleChoice.map((data, indexss) => {
                return (
                  <>
                    <Input
                      sx={{
                        fontSize: "30px",
                        fontWeight: 600,
                        padding: "10px",
                      }}
                      variant="unstyled"
                      onChange={(e) => handleQuestionChange(e, indexss)}
                      placeholder="Enter Your Question Here ?"
                    />
                    <Text my={2} fontSize={12}>
                      Options
                    </Text>
                    {data.choices.map((items, index) => (
                      <RadioGroup key={index}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap={4}
                          py={3}
                        >
                          <FaCheckSquare size={22} />
                          <Input
                            sx={{ padding: "10px" }}
                            variant="unstyled"
                            placeholder="Enter Your Choice.."
                            onChange={(e) =>
                              handleChoiceChange(e, index, indexss)
                            }
                          />
                        </Stack>
                      </RadioGroup>
                    ))}
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Button
                        variant="solid"
                        sx={{
                          background:
                            "linear-gradient(180deg, #197BBD 0%, #3391D0 100%)",
                          color: "white",
                          paddingX: "25px",
                          paddingY: "10px",
                          borderRadius: "12px",
                          width: "250px",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                        onClick={() => handleAddMore(indexss)}
                      >
                        ADD MORE +
                      </Button>
                      <Input
                        sx={{
                          padding: "10px",
                          border: "1px solid grey",
                          borderRadius: "10px",
                          width: "400px",
                        }}
                        placeholder="Enter Your Right Answer.."
                        onChange={(e) => handleRightAnswer(e, indexss)}
                      />
                      <Input
                        sx={{
                          padding: "10px",
                          border: "1px solid grey",
                          borderRadius: "10px",
                          width: "400px",
                          marginTop:"12px"
                        }}
                        placeholder="Enter Reason (Optional)"
                        onChange={(e) => handleReason(e, indexss)}
                      />
                    </Box>
                  </>
                );
              })}
            </>
          )}
          {selectedOption === "Boolean" && (
            <>
              <Input
                sx={{ fontSize: "30px", fontWeight: 600, padding: "10px" }}
                variant="unstyled"
                value={singleValue.question}
                placeholder="Enter Your Question Here ?"
                onChange={(e) => handleBooleanQuestion(e)}
              />
              <RadioGroup
                onChange={setValue}
                value={value}
                sx={{ paddingX: "1rem" }}
              >
                <Stack direction="column">
                  <Radio size="lg" name="1" colorScheme="orange" value="True">
                    True
                  </Radio>
                  <Radio size="lg" name="2" colorScheme="orange" value="False">
                    False
                  </Radio>
                </Stack>
              </RadioGroup>
              <Input
                sx={{
                  padding: "10px",
                  border: "1px solid grey",
                  borderRadius: "10px",
                  width: "400px",
                  marginTop:"12px"
                }}
                placeholder="Enter Reason (Optional)"
                onChange={(e) => handleReasonBoolean(e)}
              />
            </>
          )}
          {selectedOption === "SText" && (
            <>
              <Box display={"flex"} flexDirection={"column"}>
                <Input
                  sx={{ fontSize: "30px", fontWeight: 600, padding: "10px" }}
                  variant="unstyled"
                  placeholder="Enter Your Question Here ?"
                  onChange={(e) => handleBooleanQuestion(e)}
                />
                <Input
                  sx={{ padding: "10px" }}
                  placeholder="Enter Your Answer Here .."
                  onChange={(e) => handleTextAnswer(e)}
                />
              </Box>
            </>
          )}
          {selectedOption === "LText" && (
            <>
              <Box display={"flex"} flexDirection={"column"}>
                <Input
                  sx={{ fontSize: "30px", fontWeight: 600, padding: "10px" }}
                  variant="unstyled"
                  placeholder="Enter Your Question Here ?"
                  onChange={(e) => handleBooleanQuestion(e)}
                />
                <Textarea
                  sx={{ padding: "10px" }}
                  placeholder="Enter Your Answer Here .."
                  onChange={(e) => handleTextAnswer(e)}
                />
              </Box>
            </>
          )}
        </Box>
      )}

      {/*------------------------------------------------- QUESTION VISIBILTY CONDITIONALLY PART END-------------------------------------------------*/}
      {selectedOption !== "" && selectedOption.length > 0 && !isQuizFinish && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            paddingX: "2rem",
            gap: "1rem",
          }}
        >
          <Button sx={style.btn} onClick={() => handleNext()}>
            NEXT
          </Button>
          <Button
            sx={style.btn}
            bg={"green !important"}
            onClick={() => {
              handleFinish();
              onOpenQuizLinkModal();
            }}
          >
            FINISH
          </Button>
        </Box>
      )}
      {/*------------------------------------------------- Current Quiz Link Modal -------------------------------------------------*/}
      <Modal isOpen={isQuizLinkModal} onClose={onCloseQuizLinkModal}>
        <ModalOverlay />
        <ModalContent
          sx={{
            width: "650px",
            background: "white",
            padding: "1rem",
            border: "1px solid grey",
            borderRadius: "10px",
          }}
        >
          <ModalHeader
            sx={{
              fontSize: "18px",
              fontWeight: 600,
              opacity: 0.8,
              paddingY: "15px",
            }}
          >
            Quiz Link
          </ModalHeader>

          <ModalBody>
            <CopyToClipboard onCopy={() => onCopy()} text={isCurrentQuizLink}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid rgba(0,0,0,0.2)",
                  borderRadius: "12px",
                  padding: "10px",
                  cursor: "pointer",
                  marginY: "10px",
                  gap: "1rem",
                }}
              >
                <Text
                  sx={{
                    fontSize: "19px",
                    opacity: 0.8,
                    color: "black",
                    fontWeight: 600,
                    marginleft: "1rem",
                  }}
                >
                  {quizName}
                </Text>
                <Text sx={{ fontSize: "16px", opacity: 0.8, color: "black" }}>
                  {isCurrentQuizLink !== ""
                    ? isCurrentQuizLink
                    : "Network Error"}
                </Text>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Text sx={{ opacity: 0.7 }}>
                    {isCopied ? "Copied!" : "Copy text"}
                  </Text>
                  <MdContentCopy />
                </Box>
              </Box>
            </CopyToClipboard>
          </ModalBody>

          <ModalFooter sx={{ marginTop: "2rem" }}>
            <Button
              sx={style.modalCloseBtn}
              colorScheme="teal"
              variant="solid"
              mr={3}
              onClick={onCloseQuizLinkModal}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default CreateQuiz;
const style = {
  main: {
    paddingY: "4rem",
    paddingX: "1rem",
  },
  btn: {
    color: "white",
    paddingY: "0.5rem",
    paddingX: "1.5rem",
    borderRadius: "14px",
    backgroundColor: "#0474BA",
    fontSize: "18px",
    fontWeight: 600,
  },
  btn1: {
    color: "white",
    paddingY: "0.5rem",
    paddingX: "1rem",
    borderRadius: "14px",
    backgroundColor: "#0474BA",
    fontSize: "13px",
    fontWeight: 600,
    marginY: "1rem",
  },
  detaildCard: {
    position: "relative",
    padding: "1rem",
    borderRadius: "28px",
    border: "1px solid #F17720",
    background: "linear-gradient(180deg, #FFF 0%, #FFF 100%)",
    width: "450px",
    boxShadow:
      "0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.06)",
  },
  iconsBox: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    alignItems: "end",
  },
  labelName: {
    color: "#AEAEAE",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  scott: {
    color: "#197BBD",
    fontFamily: "Poppins",
    fontSize: "31.55px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  listItems: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    gap: "12px",
    paddingY: "0.8rem",
    transition: "all ease-out 0.5s",
    cursor: "pointer",
    width: "100%",
    paddingX: "10px",
    ":hover": {
      transform: "scale(1.1)",
      opacity: 1,
      borderRadius: "10px",
      backgroundColor: "rgba(236, 236, 236)",
    },
  },
  questionBox: {
    paddingTop: "3rem",
    width: "100%",
  },
  modalCloseBtn: {
    border: "2px solid teal",
    padding: "0.3rem 1.5rem",
    borderRadius: "10px",
    color: "teal",
    fontWeight: 600,
    transition: "all ease-out 0.5s",
    ":hover": {
      color: "white",
      backgroundColor: "teal",
    },
  },
};
