import { Box, Button, SimpleGrid, Text, border } from "@chakra-ui/react";
import OverView from "../Dashboard/OverView";
import ReservationCard from "./ReservationCard";
import DetailedCard from "../Dashboard/DetailedCard";
import { useEffect, useState } from "react";
import { handleDelDocument, sendMailUpdate } from "../../Services/Reservations";
import {
  getAllReservation,
  getSpecificdata,
  addUpdatedTimeDataInFirebase,
} from "../../Services/Reservations";
import Popup from "../Dashboard/Popup";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sendMailCancle } from "../../Services/login";
import React from "react";
import { isTimeWithinBounds } from "../../helpers/helpers";

function Dashboard() {
  const [reservations, setReservations] = useState([]);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [open, setOpen] = useState(false);
  const [specificDoc, setspecificDoc] = useState({
    data: null,
    index: null,
    appIndex: null,
    id: null,
    newSelectedDate: null,
  });

  const [counter, setCounter] = useState({
    accountingAndCpa: 0,
    lpTaxService: 0,
    lpAccounting: 0,
  });

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearch(query);
  };
  function getDayName(dateString) {
    var days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    var dayName = days[dateString.getDay()];
    return dayName;
  }
  const handleEdit = (id, appIndex, index, data) => {
    setspecificDoc({
      data: data,
      index: index,
      appIndex: appIndex,
      id: id,
      newSelectedDate: id,
    });
    setOpen(true);
  };
  const handleDeleteDocument = (id, appindex, index, sendMail) => {
    let temp = [...reservations];
    let year = new Date(id).toJSON().slice(0, 10).split("-")[0];
    let month = new Date(id).toJSON().slice(0, 10).split("-")[1];
    let day = new Date(id).toJSON().slice(0, 10).split("-")[2];
    let weekDayName = getDayName(new Date(id));
    const body = {
      firstName: temp[appindex].reservations[index].data.firstName,
      email: temp[appindex].reservations[index].data.email,
      lastName: temp[appindex].reservations[index].data.lastName,
      phone: temp[appindex].reservations[index].data.phone,
      requestedServices:
        temp[appindex].reservations[index].requestedServices.join(", "),
      date: `${month}/${day}/${year}  ${weekDayName}`,
      startTime: new Date(
        temp[appindex].reservations[index].exactStartTime.seconds * 1000
      ).toLocaleTimeString(),
      endTime: new Date(
        temp[appindex].reservations[index].exactEndTime.seconds * 1000
      ).toLocaleTimeString(),
      platform: temp[appindex].reservations[index].platform,
    };
    temp[appindex].reservations.splice(index, 1);
    setReservations(temp);
    handleDelDocument(id, temp[appindex])
      .then((data) => {
        if (sendMail) {
          // sendMailCancle(body)
          //   .then(() => {})
          //   .catch((err) => console.log(err));
          toast.success("APPOINTMENT Canceled successfully");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSave = () => {
    if (
      !isTimeWithinBounds(
        new Date(specificDoc.data.exactStartTime.seconds * 1000)
          .toTimeString()
          .slice(0, 5),
        new Date(specificDoc.data.exactEndTime.seconds * 1000)
          .toTimeString()
          .slice(0, 5),
        new Date(specificDoc.data.exactEndTime.seconds * 1000),
        specificDoc.data.platform
      )
    ) {
      console.log('time is put of bound"');
      toast.error("time is out of bound");
      return;
    }
    let temp = { ...reservations };
    if (
      specificDoc.newSelectedDate === specificDoc.id &&
      isTimeWithinBounds(
        new Date(specificDoc.data.exactStartTime.seconds * 1000)
          .toTimeString()
          .slice(0, 5),
        new Date(specificDoc.data.exactEndTime.seconds * 1000)
          .toTimeString()
          .slice(0, 5),
        new Date(specificDoc.newSelectedDate + "T00:00:00"),
        specificDoc.data.platform
      )
    ) {
      let year = new Date(specificDoc.id).toJSON().slice(0, 10).split("-")[0];
      let month = new Date(specificDoc.id).toJSON().slice(0, 10).split("-")[1];
      let day = new Date(specificDoc.id).toJSON().slice(0, 10).split("-")[2];
      let weekDayName = getDayName(new Date(specificDoc.id));
      sendMailUpdate({
        firstName: specificDoc.data.data.firstName,
        email: specificDoc.data.data.email,
        lastName: specificDoc.data.data.lastName,
        phone: specificDoc.data.data.phone,
        requestedServices: specificDoc.data.requestedServices.join(", "),
        message: "THE APPOINTMENT DATE AND TIME HAVE BEEN CHANGED. ",
        date: `${month}/${day}/${year}  ${weekDayName}`,
        startTime: new Date(
          specificDoc.data.exactStartTime.seconds * 1000
        ).toLocaleTimeString(),
        endTime: new Date(
          specificDoc.data.exactEndTime.seconds * 1000
        ).toLocaleTimeString(),
        platform: specificDoc.data.platform,
      });
      temp[specificDoc.appIndex].reservations[specificDoc.index] =
        specificDoc.data;
      handleDelDocument(specificDoc.id, temp[specificDoc.appIndex])
        .then((data) => {
          console.log(data);

          fetchData();
        })
        .catch((err) => console.log(err))
        .finally(() => setOpen(false));
    } else {
      //   console.log("if ma nh aya");
      let specificDocStartTime = new Date(
        specificDoc.data.exactStartTime.seconds * 1000
      )
        .toTimeString()
        .slice(0, 5);
      let specificDocEndTime = new Date(
        specificDoc.data.exactEndTime.seconds * 1000
      )
        .toTimeString()
        .slice(0, 5);
      let canReserve = true;
      getSpecificdata(specificDoc.newSelectedDate).then((data) => {
        let reservedTimes = data;
        if (
          reservedTimes?.reservations &&
          reservedTimes?.reservations?.length > 0
        ) {
          reservedTimes.reservations.forEach((element) => {
            const reservedStartHours = new Date(
              element.exactEndTime.seconds * 1000
            )
              .toTimeString()
              .slice(0, 5)
              .split(":")[0];
            const reservedStartMinuts = new Date(
              element.exactEndTime.seconds * 1000
            )
              .toTimeString()
              .slice(0, 5)
              .split(":")[1];
            const reservedEndtHours = new Date(
              element.exactEndTime.seconds * 1000
            )
              .toTimeString()
              .slice(0, 5)
              .split(":")[0];
            const reservedEndMinuts = new Date(
              element.exactEndTime.seconds * 1000
            )
              .toTimeString()
              .slice(0, 5)
              .split(":")[1];
            const appliedStartHours = specificDocStartTime.split(":")[0];
            const appliedStartMinuts = specificDocStartTime.split(":")[1];
            const appliedEndtHours = specificDocEndTime.split(":")[0];
            const appliedEndMinuts = specificDocEndTime.split(":")[1];
            if (
              ((appliedStartHours > reservedStartHours ||
                (appliedStartHours === reservedStartHours &&
                  appliedStartMinuts >= reservedStartMinuts)) &&
                (appliedEndtHours < reservedEndtHours ||
                  (appliedEndtHours === reservedEndtHours &&
                    appliedEndMinuts <= reservedEndMinuts))) ||
              (appliedStartHours === reservedEndtHours &&
                appliedStartMinuts === reservedEndMinuts) ||
              (appliedEndtHours === reservedStartHours &&
                appliedEndMinuts === reservedStartMinuts) ||
              (appliedEndtHours < appliedStartHours &&
                (appliedStartHours > reservedStartHours ||
                  (appliedStartHours === reservedStartHours &&
                    appliedStartMinuts >= reservedStartMinuts) ||
                  appliedEndtHours < reservedEndtHours ||
                  (appliedEndtHours === reservedEndtHours &&
                    appliedEndMinuts <= reservedEndMinuts))) ||
              (appliedEndtHours === reservedStartHours &&
                appliedEndMinuts === reservedStartMinuts)
            ) {
              canReserve = false;
              console.log("Applied time overlaps with reserved time");
            } else {
              // The applied time overlaps with the reserved time

              return;
            }
          });
        }
        if (
          canReserve &&
          isTimeWithinBounds(
            new Date(specificDoc.data.exactStartTime.seconds * 1000)
              .toTimeString()
              .slice(0, 5),
            new Date(specificDoc.data.exactEndTime.seconds * 1000)
              .toTimeString()
              .slice(0, 5),
            new Date(specificDoc.data.exactEndTime.seconds * 1000),
            specificDoc.data.platform
          )
        ) {
          toast.success("Your Appointment has  been changed successfully");
          handleDeleteDocument(
            specificDoc.id,
            specificDoc.appIndex,
            specificDoc.index
          );
          let documentId = specificDoc.newSelectedDate;
          let year = new Date(documentId).toJSON().slice(0, 10).split("-")[0];
          let month = new Date(documentId).toJSON().slice(0, 10).split("-")[1];
          let day = new Date(documentId).toJSON().slice(0, 10).split("-")[2];
          let weekDayName = getDayName(new Date(documentId));
          let temp = { ...reservedTimes };
          temp.reservations.push(specificDoc.data);
          addUpdatedTimeDataInFirebase(documentId, temp).then((data) =>
            console.log(data)
          );
          fetchData();
          console.log(specificDoc.data);
          sendMailUpdate({
            firstName: specificDoc.data.data.firstName,
            email: specificDoc.data.data.email,
            lastName: specificDoc.data.data.lastName,
            phone: specificDoc.data.data.phone,
            requestedServices: specificDoc.data.requestedServices.join(", "),
            message: "THE APPOINTMENT DATE AND TIME HAVE BEEN CHANGED. ",
            date: `${month}/${day}/${year}  ${weekDayName}`,
            startTime: new Date(
              specificDoc.data.exactStartTime.seconds * 1000
            ).toLocaleTimeString(),
            endTime: new Date(
              specificDoc.data.exactEndTime.seconds * 1000
            ).toLocaleTimeString(),
            platform: specificDoc.data.platform,
          });

          setOpen(false);
        } else {
          toast.error(
            "This time is already reserved Please select another time"
          );
          setOpen(false);
        }
      });
    }
  };

  const reservationCount = () => {
    let obj = {
      accountingAndCpa: 0,
      lpTaxService: 0,
      lpAccounting: 0,
    };
    reservations?.forEach((item) => {
      item.reservations.forEach((reservation) => {
        if (reservation.platform === "CpaAccounting") {
          obj.accountingAndCpa += 1;
        } else if (reservation.platform === "LpBooking") {
          obj.lpAccounting += 1;
        } else if (reservation.platform === "LpTax") {
          obj.lpTaxService += 1;
        }
      });
    });
    setCounter({
      accountingAndCpa: obj.accountingAndCpa,
      lpTaxService: obj.lpTaxService,
      lpAccounting: obj.lpAccounting,
    });
  };
  const chnageDate = (event) => {
    let temp = event.target.value;
    setspecificDoc((prev) => ({
      ...prev,
      newSelectedDate: temp,
    }));
  };
  const fetchData = () => {
    getAllReservation()
      .then((data) => {
        setReservations(data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 10000);

    fetchData();

    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    reservationCount();
  }, [reservations]);

  const ifNot = {
    color: "black",
    opacity: 0.3,
    width: "70vw",
    textAlign: "center",
    marginTop: "2rem",
    fontSize: "4rem",
    fontWeight: 900,
    justifyContent: "center",
    overflow: "hidden",
  };
  useEffect(() => {
    console.log(specificDoc, "specificDoc");
  }, [specificDoc]);
  return (
    <>
      <OverView handleSearch={handleSearch} search={search} />
      <ReservationCard accountingAndCpa={counter.lpTaxService} />
      <Box sx={style.main}>
        <Text sx={style.reservationHeading}>
          {reservations.length === 1 ? "APPOINTMENT:" : "APPOINTMENTS :"}
        </Text>
        <a
          style={{ textDecoration: "none", listStyle: "none" }}
          href={"https://taxappointment.lptaxservices.com/"}
          target="_blank"
        >
          <Button
            bg={"#369FFF"}
            color={"white"}
            px={"20px"}
            borderRadius={"20px"}
            gap={"5px"}
            py={"9px"}
            boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.2)"}
          >
            Add New Appointment
          </Button>
        </a>
      </Box>

      {reservations &&
        reservations.map((appointments, appindex) => {
          let year = new Date(appointments.date)
            .toJSON()
            .slice(0, 10)
            .split("-")[0];
          let month = new Date(appointments.date)
            .toJSON()
            .slice(0, 10)
            .split("-")[1];
          let day = new Date(appointments.date)
            .toJSON()
            .slice(0, 10)
            .split("-")[2];
          return (
            <React.Fragment key={appindex}>
              <Box sx={{ fontSize: "24px", fontWeight: 700, margin: "10px" }}>
                {appointments.reservations
                  .filter((data) => data?.data?.email?.includes(search))
                  .filter((item) => item.platform === "LpTax").length > 0 &&
                  `Date : ${month}/${day}/${year}`}
              </Box>
              <SimpleGrid
                columns={{ base: 1, md: 1, lg: 1, xl: 2 }}
                spacing={6}
              >
                {appointments.reservations.filter((data) =>
                  data?.data?.email?.includes(search)
                ).length > 0 ? (
                  appointments.reservations
                    .filter((data) => data.data.email.includes(search))
                    .filter((item) => item.platform === "LpTax")
                    ?.map((data, index) => {
                      return (
                        <DetailedCard
                          key={index}
                          firstName={data.data?.firstName}
                          LastName={data.data?.lastName}
                          email={data.data?.email}
                          phone={data.data?.phone}
                          services={data?.requestedServices}
                          startTime={data.exactStartTime}
                          endTime={data.exactEndTime}
                          message={data.data?.message}
                          meetingType={data?.meetingType}
                          date={new Date(appointments.date)
                            .toJSON()
                            .slice(0, 10)}
                          onClickDel={() =>
                            handleDeleteDocument(
                              appointments.date,
                              appindex,
                              index,
                              true
                            )
                          }
                          onClick={() =>
                            handleEdit(appointments.date, appindex, index, data)
                          }
                        />
                      );
                    })
                ) : (
                  <></>
                )}
              </SimpleGrid>
            </React.Fragment>
          );
        })}

      {specificDoc && (
        <Popup
          firstName={specificDoc?.data?.data?.firstName}
          LastName={specificDoc?.data?.data?.lastName}
          email={specificDoc?.data?.data?.email}
          phone={specificDoc?.data?.data?.phone}
          services={specificDoc?.data?.requestedServices}
          message={specificDoc?.data?.data?.message}
          AppointmentstartTime={specificDoc?.data?.exactStartTime}
          AppointmentendTime={specificDoc?.data?.exactEndTime}
          handleSave={() => handleSave()}
          id={specificDoc?.id}
          onClose={() => setOpen(false)}
          isOpen={open}
          setspecificDoc={setspecificDoc}
          specificDoc={specificDoc}
          chnageDate={(e) => chnageDate(e)}
        />
      )}
      <ToastContainer />
    </>
  );
}

export default Dashboard;
const style = {
  main: {
    width: "100%",
    height: "100%",
    position: "relative",
    marginTop: "30px",
    paddingX: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  reservationHeading: {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    textTransform: "uppercase",
    marginBottom: "0.5rem",
    opacity: 0.7,
  },
};
