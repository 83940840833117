import { addDoc, collection, doc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { v4 as uuidv4 } from "uuid";
export const quizFormSubmit = async (data) => {
  const id = uuidv4();
  await setDoc(doc(db, "Quiz", id), {
    quiz: data,
  });
  return id;
};
export const QuizLink = async (linkId,name) => {
  await addDoc(collection(db, "QuizLink"), {
    link: linkId,
    name:name
  });
};
export const getAllQuizLink = async () => {
  let temp = [];
  const querySnapshot = await getDocs(collection(db, "QuizLink"));
  querySnapshot.forEach((doc) => {
    temp.push(doc.data())
  });
  return temp;
};
