import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { BiSearchAlt } from "react-icons/bi";
import { HiMail } from "react-icons/hi";
import { BsFillBellFill } from "react-icons/bs";
import profile from "../../Assets/images/profile.png";

function OverView({ handleSearch, search }) {
  return (
    <Box sx={style.main}>
      <Flex sx={style.overViewBox}>
        <Flex justifyContent={"space-between"} w={"100%"} alignItems={"center"}>
          <Text sx={style.overviewHeading}>
            Overview{" "}
            <span className="spanTextOverview">Hope you have a good day</span>
          </Text>
          <Flex justifyContent={"space-between"} w={"100%"}>
            <InputGroup sx={style.input}>
              <Input
                type="text"
                placeholder="Search By Email"
                bg={"#F5F5F5"}
                className="inputSearch"
                color={"#0474BA"}
                value={search}
                onChange={handleSearch}
              />
              <InputRightElement
                pointerEvents="none"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  width: "100%",
                  height: "100%",
                  paddingRight: "12px",
                  cursor: "pointer",
                }}
              >
                <BiSearchAlt color="#0474BA" width={30} height={30} />
              </InputRightElement>
            </InputGroup>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}

export default OverView;
const style = {
  main: {
    width: "100%",
    height: "100%",
    position: "relative",
    padding: "19px",
  },
  overViewBox: {
    width: "100%",
    boxShadow: "1px 9px 17px 0px rgba(0, 0, 0, 0.12)",
    borderRadius: "20px",
    paddingY: "16px",
    paddingX: "24px",
  },
  overviewHeading: {
    color: "#1F192F",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    width: "100%",
  },
  input: {
    width: "100%",
    height: "55.523px",
    background: "#F5F5F5",
    padding: "16px",
    borderRadius: "40px",
  },
  profileBox: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
  },
  profileIconBox: {
    height: "40px",
    width: "40px",
    borderRadius: "50%",
    background: "#FFC145",
    display: { lg: "flex", xs: "none" },
    flexDirection: "row",
    alignItems: "end",
    justifyContent: "center",
  },
  profileTexts: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
  },
  andrew: {
    color: " #404040",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  andrew1: {
    color: " #AEAEAE",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
};
