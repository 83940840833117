import { Box, Button, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Input } from "@chakra-ui/react";
import { loginCheck } from "../../Services/login";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login({ setUser }) {
  const naviagte = useNavigate();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setIsLoading] = useState(false);

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const submit = () => {
    if (name && password) {
      loginCheck()
        .then((data) => {
          setIsLoading(true);

          if (data.username === name && data.password === password) {
            toast.success(`Login Successfully`, {
              theme: "colored",
            });
            localStorage.setItem("user", true);
            setIsLoading(false);
            // naviagte("/");
            setUser(true);
            // window.location.reload();
          } else {
            setIsLoading(false);
            toast.error(`Invalid Email or Password`, {
              theme: "colored",
            });
          }
        })
        .catch((err) => {
          toast.error(`the error is ${err}`);
        });
    } else {
      setIsLoading(false);
      toast.error(`fill all feilds`, {
        theme: "colored",
      });
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      submit();
    }
  };

  return (
    <>
      <Flex w={"100%"} h={"100vh"} justify={"center"} alignItems={"center"}>
        <Box sx={style.loginBox}>
          <Text sx={style.heading}>LOGIN</Text>
          <Text sx={style.heading1}>EXAM TUTORING SERVICE</Text>
          <Input
            placeholder="Enter Username"
            sx={style.Feild}
            value={name}
            onChange={(e) => handleName(e)}
            required
          />
          <Input
            placeholder="Enter Password"
            sx={style.Feild}
            value={password}
            onChange={(e) => handlePassword(e)}
            type="password"
            onKeyDown={handleKeyDown}
            required
          />
          <Button
            onClick={submit}
            sx={{
              backgroundColor: "#369FFF",
              paddingY: "0.6rem",
              color: "white",
              fontWeight: 700,
              borderRadius: "15px",
              paddingX: "3rem",
              borderColor: "#369FFF",
              boxShadow: "1px 3px 8px 0px rgba(0,0,0,0.3)",
              marginY: "0.5rem",
            }}
            colorScheme="#369FFF"
            size="lg"
          >
            {loading ? "Loading ..." : "LOGIN"}
          </Button>
        </Box>
      </Flex>
      <ToastContainer />
    </>
  );
}

export default Login;
const style = {
  loginBox: {
    color: "#369FFF",
    borderRadius: "15px",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "1px 3px 8px 0px rgba(0,0,0,0.5)",
    width: "300px",
    backgroundColor: "white",
  },
  heading: {
    fontSize: "1.5rem",
    fontWeight: 700,
    opacity: 0.8,
    marginY: "0.5rem",
    width: "100%",
    textAlign: "center",
  },
  heading1: {
    fontSize: "1rem",
    fontWeight: 700,
    opacity: 0.7,
    marginY: "0.5rem",
    width: "100%",
    textAlign: "center",
  },
  Feild: {
    marginY: "0.5rem",
    padding: "15px",
    fontSize: "16px",
    width: "95%",
    paddingX: "0.4rem",
    borderRadius: "15px",
    borderColor: "transparent",

    boxShadow: "1px 3px 8px 0px rgba(0,0,0,0.2)",
  },
};
