import {
  Box,

  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import "rc-checkbox/assets/index.css";
import { getAllQuizLink } from "../../Services/SendQuizDocument";
import { MdContentCopy } from "react-icons/md";

const UserList = () => {
  const [quizLink, setQuizLink] = useState([]);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = (index) => {
    const textarea = document.createElement('textarea');
    
    textarea.value = quizLink[index].link;

    document.body.appendChild(textarea);

    textarea.select();

    document.execCommand('copy');

    document.body.removeChild(textarea);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 3000);

  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllQuizLink();
        setQuizLink(data);
      } catch (error) {
        console.error('Error fetching quiz links:', error);
      }
    };
  
    fetchData();
    
   
  }, []);
  useEffect(()=>{
    console.log(quizLink,'quizLink');
  },[quizLink])
  return (
    <Box p={5}>
      
      <Box
        sx={{
          marginTop: "3rem",
          width: "100%",
          border: "1px solid rgba(0,0,0,0.2)",
          borderRadius: "12px",
          padding: "1.5rem",
          boxShadow: "0px 2px 23px -5px rgba(0,0,0,0.5)",
        }}
      >
      <Text fontSize={"30px"} fontWeight={600} opacity={0.8}>
        Quiz Links
      </Text>
        {quizLink &&
          quizLink.length > 0 &&
          quizLink?.map((link, index) => {
            
            return (
              <Tooltip label={isCopied ? 'Copied!' : 'Copy text'} placement='right-end' color={'whiteAlpha.900'} bg={'teal.900'} p={2} borderRadius={"10px"}>
              <Box
              key={index}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid rgba(0,0,0,0.2)",
                  borderRadius: "12px",
                  padding: "10px",
                  cursor:"pointer",
                  marginY:"10px",
                  gap:"1rem"
                }}
                onClick={()=>handleCopyClick(index)}
              >
                <Text sx={{fontSize:"16px",opacity:0.8,color:"black"}} >{link.name}</Text>
                <Text sx={{fontSize:"16px",opacity:0.8,color:"black"}} >{link.link}</Text>
                <Box sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap:"15px",

                }} >
                <Text sx={{opacity:0.7}} >Copy Link</Text>
                <MdContentCopy />
                </Box>
              </Box>
              </Tooltip>
            );
          })}
      </Box>
  
    </Box>
  );
};

export default UserList;
