export function isTimeWithinBounds(start_time, end_time, date, platform) {
  const givenDate = new Date(date);

  const localDate = new Date(givenDate);

  const currentDate = new Date();

  const startDateTime = new Date(localDate);
  const [hours, minutes] = start_time.split(":");
  startDateTime.setHours(Number(hours));
  startDateTime.setMinutes(Number(minutes));
  const hasStartTimePassed = currentDate.getTime() > startDateTime.getTime();
  console.log(
    new Date(currentDate.getTime()).toLocaleDateString(),
    "currentDate.getTime()"
  );
  console.log(
    new Date(startDateTime.getTime()).toLocaleDateString(),
    "startDateTime.getTime()"
  );
  if (hasStartTimePassed) return true;

  let openingTime = "";
  let closingTimeWeekdays = "";
  let closingTimeSaturday = "";

  if (platform === "LpTax") {
    openingTime = "08:30";
    closingTimeWeekdays = "18:00";
    closingTimeSaturday = "16:00";
  } else if (platform === "LpBooking") {
    openingTime = "08:30";
    closingTimeWeekdays = "18:00";
    closingTimeSaturday = "16:00";
    console.log(openingTime, "openingTime");
    console.log(closingTimeWeekdays, "closingTimeWeekdays");
    console.log(closingTimeSaturday, "closingTimeSaturday");
  } else if (platform === "CpaAccounting") {
    openingTime = "08:30";
    closingTimeWeekdays = "21:00";
    closingTimeSaturday = "17:00";
  }

  const openingTimeMinutes = convertToMinutes(openingTime);
  const closingTimeMinutes =
    date.getDay() === 6
      ? convertToMinutes(closingTimeSaturday)
      : convertToMinutes(closingTimeWeekdays);

  const startTimeMinutes = convertToMinutes(start_time);
  const endTimeMinutes = convertToMinutes(end_time);

  if (
    startTimeMinutes >= openingTimeMinutes &&
    endTimeMinutes <= closingTimeMinutes &&
    endTimeMinutes >= startTimeMinutes
  ) {
    return true;
  } else {
    return false;
  }
}
function convertToMinutes(time) {
  const [hours, minutes] = time.split(":");
  return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
}
