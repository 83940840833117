import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FiTrash2, FiXCircle } from "react-icons/fi";
import { FaRegEdit } from "react-icons/fa";
import three from "../../Assets/images/3.png";

function DetailCard({ startTime, endTime, date, onClickDelNew }) {
  let tempStartTime = new Date(startTime.seconds * 1000);
  let tempEndTime = new Date(endTime * 1000);

  let year = new Date(date).toJSON().slice(0, 10).split("-")[0];
  let month = new Date(date).toJSON().slice(0, 10).split("-")[1];
  let day = new Date(date).toJSON().slice(0, 10).split("-")[2];
  return (
    <>
      <Box sx={style.detaildCard}>
        <Box w={"90%"} sx={style.innerBox}>
          <img
            alt="oop"
            src={three}
            style={{ position: "absolute", right: 0, bottom: 0 }}
          />
          <Text sx={style.goldenHeading}> Blocked Date: </Text>
          <Text sx={style.whiteHeading}>{`${month}/${day}/${year}`}</Text>
          <Text sx={style.goldenHeading}> Blocked Time: </Text>
          <Text sx={style.whiteHeading}>
            {`${tempStartTime.toLocaleString().split(",")[1]} To ${
              tempEndTime.toLocaleString().split(",")[1]
            }`}
          </Text>
        </Box>
        <Box sx={style.absoluteDetele}>
          <Button
            bg={"red"}
            color={"white"}
            px={"20px"}
            borderRadius={"20px"}
            gap={"5px"}
            py={"5px"}
            boxShadow={"0px 4px 4px 0px rgba(255, 0, 0, 0.4)"}
            onClick={() => onClickDelNew()}
          >
            <FiXCircle color="white" width={40} height={40} /> Delete
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default DetailCard;
const style = {
  detaildCard: {
    position: "relative",
    padding: "1rem",
    borderRadius: "28px",
    border: "1px solid #F17720",
    background: "linear-gradient(180deg, #FFF 0%, #FFF 100%)",

    boxShadow:
      "0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.06)",
  },
  absoluteDetele: {
    position: "absolute",
    top: 2,
    right: 2,
  },
  iconsBox: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    alignItems: "end",
  },
  labelName: {
    color: "#AEAEAE",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  scott: {
    color: "#197BBD",
    fontFamily: "Poppins",
    fontSize: "31.55px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  phone: {
    color: "#439A86",
    fontFamily: "Poppins",
    fontSize: "25px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  email: {
    color: "#BB4430",
    fontFamily: "Poppins",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  innerBox: {
    borderRadius: "26px",
    background: "linear-gradient(180deg, #197BBD 0%, #3391D0 100%)",
    padding: "13px",
    position: "relative",
  },
  goldenHeading: {
    color: "#FFD84D",
    fontFamily: "Poppins",
    fontSize: { base: "12px", lg: "16px" },
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  whiteHeading: {
    color: "white",
    fontFamily: "Poppins",
    fontSize: { base: "12px", lg: "16px" },
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginY: "5px",
  },
  para: {
    fontSize: "13px",
  },
  paras: {
    fontSize: "24px",
    width: "70%",
    textAlign: "center",
  },
};
