import { Box, Button, SimpleGrid, Text, border } from "@chakra-ui/react";
import OverView from "../Dashboard/OverView";
import DetailedCard from "../Dashboard/DetailedCard";
import { useEffect, useState } from "react";
import {
  getAllReservation,
  handleDelDocument,
} from "../../Services/Reservations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import DetailCard from "./DetailCard";

function BlockDates() {
  const [reservations, setReservations] = useState([]);

  const fetchData = () => {
    getAllReservation()
      .then((data) => {
        setReservations(data);
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteDocument = (id, appindex, index, sendMail) => {
    let temp = [...reservations];

    temp[appindex].reservations.splice(index, 1);
    setReservations(temp);
    handleDelDocument(id, temp[appindex])
      .then((data) => {
        if (sendMail) {
          toast.success("Block Date Canceled successfully");
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 10000);

    fetchData();

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <OverView />
      <Box sx={style.main}></Box>

      {reservations &&
        reservations.map((appointments, appindex) => {
          let year = new Date(appointments.date)
            .toJSON()
            .slice(0, 10)
            .split("-")[0];
          let month = new Date(appointments.date)
            .toJSON()
            .slice(0, 10)
            .split("-")[1];
          let day = new Date(appointments.date)
            .toJSON()
            .slice(0, 10)
            .split("-")[2];
          return (
            <React.Fragment key={appindex}>
              <SimpleGrid
                columns={{ base: 1, md: 1, lg: 1, xl: 2 }}
                spacing={6}
              >
                {appointments.reservations ? (
                  appointments.reservations?.map((data, index) => {
                    {
                      console.log(data.isBlocked);
                    }
                    return (
                      <SimpleGrid
                        columns={{ base: 1, md: 1, lg: 1, xl: 2 }}
                        spacing={6}
                      >
                        <React.Fragment key={index}>
                          {/* {data.isBlocked && (
                            <Box
                              sx={{
                                fontSize: "24px",
                                fontWeight: 700,
                                margin: "10px",
                              }}
                            >
                              {`Date : ${month}/${day}/${year}`}
                            </Box>
                          )} */}
                          {!data.data?.email && data.exactStartTime && (
                            <DetailCard
                              // onClickDelNew={handleDeleteDocument}
                              onClickDelNew={() =>
                                handleDeleteDocument(
                                  appointments.date,
                                  appindex,
                                  index,
                                  true
                                )
                              }
                              startTime={data.exactStartTime}
                              endTime={data.exactEndTime}
                              date={new Date(appointments.date)
                                .toJSON()
                                .slice(0, 10)}
                            />
                          )}
                        </React.Fragment>
                      </SimpleGrid>
                    );
                  })
                ) : (
                  <></>
                )}
              </SimpleGrid>
            </React.Fragment>
          );
        })}

      <ToastContainer />
    </>
  );
}

export default BlockDates;
const style = {
  main: {
    width: "100%",
    height: "100%",
    position: "relative",
    marginTop: "30px",
    paddingX: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  reservationHeading: {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    textTransform: "uppercase",
    marginBottom: "0.5rem",
    opacity: 0.7,
  },
};
