import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FiTrash2, FiXCircle } from "react-icons/fi";
import { FaRegEdit } from "react-icons/fa";
import three from "../../Assets/images/3.png";

function DetailedCard({
  firstName,
  LastName,
  email,
  phone,
  services,

  message,
  onClick,
  startTime,
  endTime,
  onClickDel,
  date,
  onClickDelNew,
  meetingType,
}) {
  const [isDelPopup, setIsDelPopup] = useState(false);

  const cloaseDelPopup = (val) => {
    setIsDelPopup(val);
  };

  const delAndClosePopop = () => {
    onClickDel();
    setIsDelPopup(false);
  };

  let tempStartTime = new Date(startTime.seconds * 1000);
  let tempEndTime = new Date(endTime.seconds * 1000);
  console.log(tempStartTime, "tempStartTime");
  console.log(tempEndTime, "tempEndTime");

  let year = new Date(date).toJSON().slice(0, 10).split("-")[0];
  let month = new Date(date).toJSON().slice(0, 10).split("-")[1];
  let day = new Date(date).toJSON().slice(0, 10).split("-")[2];
  return (
    <>
      <Box sx={style.detaildCard}>
        <Box sx={style.iconsBox}>
          <Flex gap={"10px"} justify={"center"} alignItems={"center"}>
            <Button
              bg={"red"}
              color={"white"}
              px={"20px"}
              borderRadius={"20px"}
              gap={"5px"}
              py={"5px"}
              boxShadow={"0px 4px 4px 0px rgba(255, 0, 0, 0.4)"}
              onClick={() => onClickDelNew()}
            >
              <FiXCircle color="white" width={40} height={40} /> Delete
            </Button>
            <Button
              bg={"#FF7E07"}
              color={"white"}
              px={"20px"}
              borderRadius={"20px"}
              gap={"5px"}
              py={"5px"}
              boxShadow={"0px 4px 4px 0px rgba(255, 0, 0, 0.4)"}
              onClick={() => cloaseDelPopup(true)}
            >
              <FiXCircle color="white" width={40} height={40} /> Cancel
            </Button>
            <Button
              bg={"#369FFF"}
              color={"white"}
              px={"20px"}
              borderRadius={"20px"}
              gap={"5px"}
              py={"5px"}
              boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.2)"}
              onClick={onClick}
            >
              <FaRegEdit opacity={0.8} width={35} height={35} /> EDIT
            </Button>
          </Flex>
        </Box>
        <Flex w={"100%"} justify={"space-between"} alignItems={"center"}>
          <Box>
            <Box mb={"12px"}>
              <Text sx={style.labelName}>FIRST Name </Text>
              <Text sx={style.scott}>{`${firstName}`} </Text>
            </Box>
            <Box mb={"12px"}>
              <Text sx={style.labelName}>LAST Name </Text>
              <Text sx={style.scott}>{`${LastName}`} </Text>
            </Box>
            <Box mb={"12px"}>
              <Text sx={style.labelName}>Phone </Text>
              <Text sx={style.phone}>{phone} </Text>
            </Box>
            <Box mb={"12px"}>
              <Text sx={style.labelName}>Email </Text>
              <Text sx={style.email}>{email}</Text>
            </Box>
          </Box>
          <Box
            w={"100%"}
            display={"flex"}
            justifyContent={"end"}
            alignItems={"end"}
            mt={"10px"}
          >
            <Box w={"90%"} sx={style.innerBox}>
              <img
                alt="oop"
                src={three}
                style={{ position: "absolute", right: 0, bottom: 0 }}
              />
              <Text sx={style.goldenHeading}> Appointment Date: </Text>
              <Text sx={style.whiteHeading}>{`${month}/${day}/${year}`}</Text>
              <Text sx={style.goldenHeading}> Appointment Time: </Text>
              <Text sx={style.whiteHeading}>
                {`${tempStartTime.toLocaleString().split(",")[1]} To ${
                  tempEndTime.toLocaleString().split(",")[1]
                }`}
              </Text>
              <Text sx={style.goldenHeading}> Requested Services: </Text>
              {services &&
                services.map((chunk, index) => (
                  <Text sx={style.whiteHeading} key={index}>
                    {chunk}
                  </Text>
                ))}
              <Text sx={style.goldenHeading}> Meeting Type: </Text>

              <Text sx={style.whiteHeading}>
                {meetingType === "Physical"
                  ? "My Business Location"
                  : meetingType}
              </Text>
            </Box>
          </Box>
        </Flex>
        <Text sx={style.labelName}>Message </Text>
        <Text sx={style.para}>{message}</Text>
      </Box>
      <Modal isOpen={isDelPopup} onClose={() => cloaseDelPopup(false)}>
        <ModalOverlay />
        <ModalContent
          w={"100%"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <ModalBody
            // w={{ xl: "50%", base: "100%" }}
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
            justifyContent={"center"}
            bg={"white"}
            paddingY={10}
            paddingX={5}
            sx={{
              boxShadow: "0px 3px 18px 0px rgba(0,0,0,0.5)",
              borderRadius: "1rem",
              border: "1px solid orange",
            }}
          >
            <Text sx={style.paras}>
              Are you sure you want to cancel reservation ?
            </Text>
            <ModalFooter gap={10} paddingY={2}>
              <Button
                variant="solid"
                sx={{
                  background: "red",
                  color: "white",
                  paddingX: "25px",
                  paddingY: "10px",
                  borderRadius: "12px",
                }}
                boxShadow={"0px 4px 4px 0px rgba(255, 0, 0, 0.4)"}
                onClick={delAndClosePopop}
              >
                Yes
              </Button>
              <Button
                variant="solid"
                sx={{
                  background:
                    "linear-gradient(180deg, #197BBD 0%, #3391D0 100%)",
                  color: "white",
                  paddingX: "25px",
                  paddingY: "10px",
                  borderRadius: "12px",
                }}
                boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.4)"}
                onClick={() => cloaseDelPopup(false)}
              >
                No
              </Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DetailedCard;
const style = {
  detaildCard: {
    position: "relative",
    padding: "1rem",
    borderRadius: "28px",
    border: "1px solid #F17720",
    background: "linear-gradient(180deg, #FFF 0%, #FFF 100%)",

    boxShadow:
      "0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.06)",
  },
  iconsBox: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    alignItems: "end",
  },
  labelName: {
    color: "#AEAEAE",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  scott: {
    color: "#197BBD",
    fontFamily: "Poppins",
    fontSize: "31.55px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  phone: {
    color: "#439A86",
    fontFamily: "Poppins",
    fontSize: "25px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  email: {
    color: "#BB4430",
    fontFamily: "Poppins",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  innerBox: {
    borderRadius: "26px",
    background: "linear-gradient(180deg, #197BBD 0%, #3391D0 100%)",
    padding: "13px",
    position: "relative",
  },
  goldenHeading: {
    color: "#FFD84D",
    fontFamily: "Poppins",
    fontSize: { base: "12px", lg: "16px" },
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  whiteHeading: {
    color: "white",
    fontFamily: "Poppins",
    fontSize: { base: "12px", lg: "16px" },
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginY: "5px",
  },
  para: {
    fontSize: "13px",
  },
  paras: {
    fontSize: "24px",
    width: "70%",
    textAlign: "center",
  },
};
